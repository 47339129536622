import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Section from 'components/Section'
import Title from 'components/Title'
import PlansTable from 'components/pages/memberships/PlansTable'
import AuthButton from 'features/auth/components/AuthButton'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as subscriptions from 'libs/subscriptions'

export default function Plans() {
  useEventPageVisited('Plans')

  return (
    <Layout className="Plans">
      <Head title="Memberships" />
      <Section size="large">
        <Container size="xxlarge">
          <Row size="xxlarge">
            <Container flush>
              <Title
                subtext="Learn more about Bold’s variety of membership types. Sign up today and select the option that fits your needs and your budget best."
                size="large">
                A membership for everybody
              </Title>
            </Container>
            <PlansTable />
          </Row>
          <Title
            subtext={`Start your risk free ${subscriptions.TRIAL_DAYS}-day trial today!`}
            actions={<AuthButton size="xlarge" />}
            flush>
            Join Bold today
          </Title>
        </Container>
      </Section>
    </Layout>
  )
}
